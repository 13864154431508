// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-plant-profiles-introduction-jsx": () => import("./../../../src/pages/plant-profiles-introduction.jsx" /* webpackChunkName: "component---src-pages-plant-profiles-introduction-jsx" */),
  "component---src-pages-plant-profiles-jsx": () => import("./../../../src/pages/plant-profiles.jsx" /* webpackChunkName: "component---src-pages-plant-profiles-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-plant-profile-jsx": () => import("./../../../src/templates/plantProfile.jsx" /* webpackChunkName: "component---src-templates-plant-profile-jsx" */)
}

